import { firebaseAuth, useRecordOptionsStore, useUserStore } from "@/entities";
import GoogleOneTapLogin from "react-google-one-tap-login";
import { LOCAL_STORAGE_KEYS, QUERY_KEYS } from "@/shared/constants";
import { useQueryClient } from "@tanstack/react-query";
import { GoogleAuthProvider, signInWithCredential } from "firebase/auth";
import {
  authControllerGoogleAuth,
  GoogleAuthDtoReq,
} from "@/shared/api/generated.ts";

export const GoogleOneTapSignIn = () => {
  const { email, login } = useUserStore();
  const { invalidateOptions } = useRecordOptionsStore();
  const queryClient = useQueryClient();

  const handleAuthorization = async (token: string) => {
    login(token);
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
    await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
    await invalidateOptions();
  };

  const handleCallback = async (response: any) => {
    try {
      const credential = GoogleAuthProvider.credential(response.credential);
      const result = await signInWithCredential(firebaseAuth, credential);
      const token = await result.user.getIdToken();
      const telegramId = localStorage.getItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      const payload: GoogleAuthDtoReq = { token };

      if (telegramId) {
        payload.telegramId = telegramId;
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TELEGRAM_ID);
      }

      const { access_token, telegramLink } =
        await authControllerGoogleAuth(payload);
      await handleAuthorization(access_token);

      if (telegramLink) {
        window.open(telegramLink, "_blank", "noopener,noreferrer");
      }
    } catch (e) {
      console.log(e);
    }
  };

  if (email) return null;

  return (
    <GoogleOneTapLogin
      googleAccountConfigs={{
        client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
        callback: handleCallback,
      }}
      onError={(error) => console.log(error)}
    />
  );
};
