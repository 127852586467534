import { FC, useEffect, useRef, useState } from "react";
import { useAutoTranslation, useLangStore, useUserStore } from "@/entities";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { UserPlan } from "@/shared/types";
import { UiButton } from "@/shared/ui";
import { capitalize, shuffleArr } from "@/shared/utils";
import { useNavigate } from "react-router-dom";

export const SuggestTranscribingText: FC = () => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [started, setStarted] = useState(false);
  const { email, plan } = useUserStore();
  const intervalRef = useRef<any>(null);
  const [phrases, setPhrases] = useState<string[]>([]);

  const { lang } = useLangStore();

  const navigate = useNavigate();

  const { t } = useAutoTranslation();

  const handleSignUp = () => {
    navigate(ROUTER_PATHS.AUTH_SIGN_UP);
  };

  const mainPhrasesArr = [
    t(
      LANG_KEYS.MANUALLY_SELECT_THE_TRANSCRIPTION_LANG.key,
      LANG_KEYS.MANUALLY_SELECT_THE_TRANSCRIPTION_LANG.value,
    ),
    t(
      LANG_KEYS.AVOID_USING_MULTIPLE_LANGUAGES_WHEN_SPEAKING.key,
      LANG_KEYS.AVOID_USING_MULTIPLE_LANGUAGES_WHEN_SPEAKING.value,
    ),
    t(
      LANG_KEYS.DONT_FORGET_TO_VISIT_THE_SETTING_PAGE_TO_ADJUST.key,
      LANG_KEYS.DONT_FORGET_TO_VISIT_THE_SETTING_PAGE_TO_ADJUST.value,
    ),
    t(
      LANG_KEYS.SPEAKING_CLEARLY_AND_AT_MODERATE_PACE_CAN.key,
      LANG_KEYS.SPEAKING_CLEARLY_AND_AT_MODERATE_PACE_CAN.value,
    ),
    t(
      LANG_KEYS.MAKE_SURE_YOUR_RECORDING_ENVIRONMENT_IS_QUIET.key,
      LANG_KEYS.MAKE_SURE_YOUR_RECORDING_ENVIRONMENT_IS_QUIET.value,
    ),
    t(
      LANG_KEYS.IN_GROUP_SETTINGS_ENSURE_THAT_ONLY_ONE_PERSON_SPEAKS.key,
      LANG_KEYS.IN_GROUP_SETTINGS_ENSURE_THAT_ONLY_ONE_PERSON_SPEAKS.value,
    ),
  ];

  useEffect(() => {
    setPhrases(shuffleArr(mainPhrasesArr));
    if (!email) {
      setCurrentText(
        t(
          LANG_KEYS.SIGN_UP_TO_EXTEND_YOUR_TIME_LIMIT_TO_6_MIN.key,
          LANG_KEYS.SIGN_UP_TO_EXTEND_YOUR_TIME_LIMIT_TO_6_MIN.value,
        ),
      );
    } else if (email && plan == UserPlan.FREE) {
      setCurrentText(
        t(
          LANG_KEYS.UPGRADE_AND_EXTEND_YOUR_TIME_LIMIT.key,
          LANG_KEYS.UPGRADE_AND_EXTEND_YOUR_TIME_LIMIT.value,
        ),
      );
    } else {
      setCurrentText(mainPhrasesArr[currentIndex]);
    }

    intervalRef.current = setInterval(() => {
      setStarted(true);
      setCurrentText(phrases[currentIndex]);
      setCurrentIndex((prev) =>
        prev + 1 < mainPhrasesArr.length ? prev + 1 : 0,
      );
    }, 3500);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (started) {
      setCurrentText(phrases[currentIndex]);
    }
  }, [currentIndex, started]);

  useEffect(() => {
    setPhrases(shuffleArr(mainPhrasesArr));
  }, [lang]);

  if (email && plan != UserPlan.FREE) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        marginTop: "10px",
        gap: "20px",
        alignItems: "center",
      }}
    >
      {!!currentText && (
        <p style={{ color: "#fff", fontSize: 14, textAlign: "center" }}>
          {currentText}
        </p>
      )}
      {!email && (
        <div className="flex justify-center">
          <UiButton variant="white" onClick={handleSignUp}>
            <div style={{ color: "#000" }}>
              {capitalize(
                t(LANG_KEYS.SIGN_UP_NOW.key, LANG_KEYS.SIGN_UP_NOW.value),
              )}
            </div>
          </UiButton>
        </div>
      )}
    </div>
  );
};
