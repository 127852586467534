import { FastService, NotesList, OptionsSettings } from "@/widgets";
import styled from "styled-components";
import { GoogleOneTapSignIn, RecordTranscribe } from "@/features";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { useAutoTranslation, useUserStore, useUtilityStore } from "@/entities";
import { TranscribeProcess } from "@/features/transcribe";
import { Link } from "react-router-dom";
import { AnimatePresence, motion as m } from "framer-motion";

export const HomePage = () => {
  // useEffect(() => {
  //   translateAllKeys();
  // }, []);

  const { t } = useAutoTranslation();
  const { email } = useUserStore();
  const {
    isRegisterForFileAlert,
    isFileLimitSizeAlert,
    isMaxFileLimitAlert,
    isLengthAudioSizeError,
  } = useUtilityStore();
  return (
    <HomeSection>
      <GoogleOneTapSignIn />
      <HomeWrapper>
        <Headings>
          <Subtitle>
            {t(
              LANG_KEYS.SUPERCHARGE_YOUR_PRODUCTIVITY.key,
              LANG_KEYS.SUPERCHARGE_YOUR_PRODUCTIVITY.value,
            )}
          </Subtitle>
          <Title>
            {t(
              LANG_KEYS.TRANSFORM_YOUR_THOUGHTS_INTO_CLEAR_TEXT_NOTES.key,
              LANG_KEYS.TRANSFORM_YOUR_THOUGHTS_INTO_CLEAR_TEXT_NOTES.value,
            )}
          </Title>
        </Headings>
        <AnimatePresence mode="wait">
          {!email && isRegisterForFileAlert && (
            <m.div
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.35, ease: "easeOut" }}
              exit={{ opacity: 1 }}
            >
              <AlertWrapper>
                <ErrorText>
                  {t(
                    LANG_KEYS.NOT_ALLOW_UPLOAD_TEXT.key,
                    LANG_KEYS.NOT_ALLOW_UPLOAD_TEXT.value,
                  )}
                  &nbsp;
                  <Link to={ROUTER_PATHS.AUTH_SIGN_UP}>
                    <LinkText>
                      {t(
                        LANG_KEYS.JOIN_US_TODAY.key,
                        LANG_KEYS.JOIN_US_TODAY.value,
                      )}
                    </LinkText>
                  </Link>
                </ErrorText>
              </AlertWrapper>
            </m.div>
          )}

          {isFileLimitSizeAlert && (
            <m.div
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.35, ease: "easeOut" }}
              exit={{ opacity: 1 }}
            >
              <AlertWrapper>
                <ErrorText>
                  {t(
                    LANG_KEYS.FILE_SIZE_LIMIT_FREE_PLAN.key,
                    LANG_KEYS.FILE_SIZE_LIMIT_FREE_PLAN.value,
                  )}
                  &nbsp;
                  <Link
                    className="lowercase underline text-[#4f46e5]"
                    to={ROUTER_PATHS.PRICING}
                  >
                    {t(
                      LANG_KEYS.PRICING_PAGE.key,
                      LANG_KEYS.PRICING_PAGE.value,
                    )}
                  </Link>
                  .
                </ErrorText>
              </AlertWrapper>
            </m.div>
          )}

          {isMaxFileLimitAlert && (
            <m.div
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.35, ease: "easeOut" }}
              exit={{ opacity: 1 }}
            >
              <AlertWrapper>
                <ErrorText>
                  {t(
                    LANG_KEYS.MAX_FILE_LIMIT.key,
                    LANG_KEYS.MAX_FILE_LIMIT.value,
                  )}
                </ErrorText>
              </AlertWrapper>
            </m.div>
          )}

          {isLengthAudioSizeError && (
            <m.div
              initial={{ opacity: 0, scale: 1.2 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.35, ease: "easeOut" }}
              exit={{ opacity: 1 }}
            >
              <AlertWrapper>
                <ErrorText>
                  {t(
                    LANG_KEYS.THREE_SECONDS_ERROR_MSG.key,
                    LANG_KEYS.THREE_SECONDS_ERROR_MSG.value,
                  )}
                </ErrorText>
              </AlertWrapper>
            </m.div>
          )}
        </AnimatePresence>

        <RecordingWrapper>
          <TranscribeProcess />
          <RecordTranscribe />
          <div className="mt-5 flex justify-center">
            <OptionsSettings />
          </div>
          <div className="mt-5">
            <FastService />
          </div>
        </RecordingWrapper>
      </HomeWrapper>
      <div className="mt-5">
        <NotesList />
      </div>
    </HomeSection>
  );
};

const Subtitle = styled.h2`
  color: var(--main-purple);
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  color: #111827;
  text-align: center;
  max-width: 700px;
  line-height: 75px;
  white-space: pre-wrap;
  word-break: break-word;

  @media screen and (max-width: 768px) {
    font-size: 3.2rem;
    line-height: 60px;
  }
`;

const HomeSection = styled.section`
  padding: 35px 15px;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RecordingWrapper = styled.div`
  margin-top: 40px;
  width: 100%;
`;

const AlertWrapper = styled.div`
  max-width: 80vw;
  margin: 20px auto;
  text-align: center;
`;

const ErrorText = styled.p`
  font-size: 20px;
  color: red;
  display: inline;
`;
const LinkText = styled.p`
  color: var(--main-purple);
  font-size: 20px;
  display: inline;
  &:hover {
    text-decoration: underline;
  }
`;
